<template>
<div v-if="items">
<ion-grid>
    <ion-row v-for="item in items" :key="item.id">
        <ion-col>
            
            <ion-card>
                <ion-card-header>
                    <ion-card-title>{{ item.Title }}</ion-card-title>
                </ion-card-header>

                <ion-card-content>
                    {{item.Content}}
                    <ion-button color="primary" @click="share(item.Title, item.Title, 'https://www.gatewaybaptist.com/page/623?Item=' + item.Id, 'Share')" >Share</ion-button>
                </ion-card-content>

            </ion-card>    
                
        </ion-col>
    </ion-row>
</ion-grid>
</div>
<div v-if="!items">
  <loading-template />
</div>
</template>

<script>
import axios from "axios";
import { Share } from '@capacitor/share';
import { IonCol, IonGrid, IonRow, IonCard } from '@ionic/vue';
import LoadingTemplate from '@/components/System/Loading.vue'
export default {
  name: "get-request",
  components: { IonCol, IonGrid, IonRow, IonCard, LoadingTemplate },
  data() {
    return {
      items: null,
    };
  },
  methods: {
      async share(title, text, url, dialogTitle) {
          await Share.share({
            title: title,
            text: text,
            url: url,
            dialogTitle: dialogTitle,
});
      }
  },
  created() {
    // Simple GET request using axios
    const config = {
      headers: {
        "Authorization-Token": process.env.VUE_APP_ROCK_RMS_APPLICATION_KEY,
        "Accept": "application/json"
      },
    };

    axios
      .get(process.env.VUE_APP_ROCK_RMS_APPLICATION_KEY + "api/ContentChannelItems?$filter=ContentChannelId eq 3&$top=1", config)
      .then((response) => (this.items = response.data));
  },
};
</script>
